<template>
  <div>
    <div
      class="min-h-50 d-flex w-100 align-items-center justify-content-center"
      v-if="!hasSnapshot && !loading"
    >
      <div class="text-center">
        <b-icon icon="shield" class="h1" />
        <p class="my-3">
          Ainda não há clientes sincronizados para envio do atestado.
        </p>
        <b-button
          v-b-modal.select-clients-provider
          v-if="is_provider && enabled"
        >
          <b-icon icon="arrow-down-up" class="mr-2" />
          <span>Sincronizar agora</span>
        </b-button>
      </div>
    </div>

    <div v-else>
      <div class="d-flex align-items-center justify-content-start mb-3">
        <small class="mr-2">
          Ultima atualização {{ clients.updated_at | dateFull }}</small
        >
        <unicon
          v-if="is_provider && enabled"
          name="redo"
          v-b-modal.select-clients-provider
          class="pointer small"
          style="font-size: 12px"
          height="16"
          width="16"
        />
      </div>
      <cards-list grid="4">
        <b-card
          no-body
          class="mb-3 card-security p-3 mr-1"
          :class="[
            selecteds.includes(client) && 'selected',
            selecteds.length === 5 && !selecteds.includes(client) && 'disabled',
          ]"
          v-for="(client, index) in clients.data"
          :key="index"
        >
          <div class="mr-md-4">
            <span class="d-block title">
              {{ client.social_reason }}
            </span>
            <small class="text-muted">{{ client.situation }}</small>
          </div>
          <div>
            <div class="mt-2 scrollable-text">
              <strong>CNPJ:</strong>
              {{ client.cnpj | VMask("###.###.###-##") }}
            </div>
          </div>
        </b-card>
      </cards-list>
    </div>

    <b-modal
      id="select-clients-provider"
      size="lg"
      centered
      scrollable
      hide-header
      hide-footer
    >
      <template #modal-header> </template>
      <div class="py-3">
        <ModalSelect @close="handleSetSelecteds" />
      </div>
    </b-modal>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import ModalSelect from "./ModalSelect.vue";

export default {
  components: {
    ModalSelect,
  },
  computed: {
    ...mapGetters(["is_preview", "preview_personify", "user", "is_provider"]),
  },

  data() {
    return {
      enabled: false,
      hasSnapshot: false,
      loading: false,
      search: "",
      selecteds: [],
      service_id: "",
      provider_id: "",
      homologationId: "",
      clients: [],
    };
  },
  methods: {
    ...mapActions(["set_snapshot", "get_snapshot", "show_loading"]),

    getSnapshot() {
      this.$bvModal.show("modal-generating-snapshot");
      this.loading = true;
      this.show_loading({
        loading: true,
      });
      this.get_snapshot({
        type: "technical-capacity",
        service_id: this.service_id,
        provider_id: this.provider_id,
      }).then((response) => {
        this.enabled = response.enabled;
        this.$bvModal.hide("modal-generating-snapshot");
        if (!response?.created_at) {
          this.hasSnapshot = false;
        } else {
          this.hasSnapshot = true;
          this.clients = response;
        }
        this.show_loading({
          loading: false,
        });
        this.loading = false;
      });
    },
    handleSetSelecteds(selecteds) {
      this.$bvModal.hide("select-clients-provider");
      this.set_snapshot({
        type: "technical-capacity",
        service_id: this.service_id,
        provider_id: this.provider_id,
        data: selecteds.flatMap(({ id }) => [id]),
      }).then(() => {
        this.$bvModal.hide("modal-generating-snapshot");
        this.hasSnapshot = true;
        this.getSnapshot();
      });
    },
  },
  mounted() {
    this.service_id = this.$route.params.service_id;
    this.provider_id = this.$route.params.provider_id;
    this.homologationId = this.$route.params.homologation_id;
    this.getSnapshot();
  },
};
</script>
<style lang="scss">
.content-capacity {
  margin: 0 -10px;
  .card-security {
    display: inline-grid;
    user-select: none;
    margin: 0 10px;
    &.disabled {
      opacity: 0.4;
      filter: grayscale(5);
      cursor: not-allowed;
      pointer-events: none;
      user-select: none;
    }
    &.selected {
      border-color: $primary;
      .title {
        color: $primary;
      }
    }
  }
}
</style>
